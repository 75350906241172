<template>
  <div class="edit-player-modal-content">
    <div class="edit-player-form">
      <div
        v-if="confirmDeleteActive"
        class="disabled-layer-root"
      />
      <div class="player-info-title">
        <p>Player display information</p>
      </div>
      <div class="player-info">
        <div class="input-label">
          First name
        </div>
        <TextInput
          :model-value="firstName"
          :placeholder="'Input first name'"
          no-clear-button
          @update:modelValue="onPlayerFirstNameUpdate"
        />
        <div class="input-label">
          Last name
        </div>
        <TextInput
          :model-value="lastName"
          :placeholder="'Input last name'"
          no-clear-button
          @update:modelValue="onPlayerLastNameUpdate"
        />
        <div class="input-label">
          Team name
        </div>
        <TextInput
          :model-value="teamName"
          :placeholder="'Team name'"
          :readonly="true"
          no-clear-button
        />
        <PlayerPositionEdit
          :position="data.position"
          @updatePosition="onPlayerPositionUpdate"
        />
      </div>
    </div>
    <div
      v-if="!confirmDeleteActive"
      class="player-squad-actions"
    >
      <div class="left-side">
        <div
          v-if="!isCreate"
          class="delete-btn"
          @click="toggleConfirmDelete"
        >
          Delete player
        </div>
      </div>
      <div class="right-side">
        <div
          @click="closeModal"
          class="dismiss-btn"
        >
          Dismiss
        </div>
        <Button
          :variant="'primary'"
          @click="onSaveChanges"
          :disabled="isSubmitDisabled"
        >
          Save changes
        </Button>
      </div>
    </div>
    <div
      v-else
      class="confirm-delete"
    >
      <div class="left-side">
        Confirm delete for player {{ formatPlayerName(playerData.name) }}
      </div>
      <div class="right-side">
        <div
          @click="toggleConfirmDelete"
          class="dismiss-btn"
        >
          Dismiss
        </div>
        <Button
          :variant="'danger'"
          @click="deletePlayer"
        >
          Delete player
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { cloneDeep, isEqual } from 'lodash';
import {
  formatPlayerName,
  formatPlayerFirstName,
  formatPlayerLastName,
} from '@/services/helpers/player-setup-mapper';
import TextInput from '@/components/common/TextInput';
import Button from '@/components/common/Button';
import PlayerPositionEdit from './PlayerPositionEdit';

export default {
  emits: ['closeModal'],
  props: {
    playerData: {
      type: Object,
      default: () => {},
    },
    isCreate: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    TextInput,
    Button,
    PlayerPositionEdit,
  },
  setup(props, { emit }) {
    const store = useStore();
    const originalData = ref(cloneDeep(props.playerData));
    const data = ref(cloneDeep(props.playerData));
    const isSubmitDisabled = computed(() => isEqual(originalData.value, data.value));
    const firstName = ref(formatPlayerFirstName(props.playerData.name));
    const lastName = ref(formatPlayerLastName(props.playerData.name));
    const teamName = ref(props.playerData.teamName);
    const confirmDeleteActive = ref(false);

    const updatePlayerName = () => {
      data.value.name = `${lastName.value}, ${firstName.value}`;
    };

    const onPlayerFirstNameUpdate = (val) => {
      firstName.value = val;
      updatePlayerName();
    };
    const onPlayerLastNameUpdate = (val) => {
      lastName.value = val;
      updatePlayerName();
    };
    const onPlayerPositionUpdate = (val) => {
      data.value.position = val;
    };
    const toggleConfirmDelete = () => { confirmDeleteActive.value = !confirmDeleteActive.value; };
    const closeModal = () => emit('closeModal');
    const onSaveChanges = () => {
      if (props.isCreate) {
        store.dispatch('manuallyCreatePlayer', data.value);
      } else {
        store.dispatch('updatePlayerData', data.value);
      }
    };
    const deletePlayer = () => store.dispatch('manuallyRemovePlayer', props.playerData);

    return {
      firstName,
      lastName,
      teamName,
      isSubmitDisabled,
      confirmDeleteActive,
      onPlayerFirstNameUpdate,
      onPlayerLastNameUpdate,
      onSaveChanges,
      closeModal,
      formatPlayerName,
      toggleConfirmDelete,
      deletePlayer,
      onPlayerPositionUpdate,
      data,
    };
  },
};
</script>

<style lang="scss">
  .edit-player-modal-content {
    .edit-player-form {
      padding: 16px;
      position: relative;
      .disabled-layer-root {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .5);
        z-index: 1;
      }
    }
    .player-info-title,
    .player-squad-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
    }
    .player-info {
      padding-bottom: 16px;
      .input-label {
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 16px;
        margin-bottom: 4px;
        color: $gray800;
      }
      .text-field {
        width: 100%;
      }
    }
    .player-squad-actions,
    .confirm-delete {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      padding: 16px;
      border-top: 1px solid $gray400;

      .left-side,
      .right-side {
        width: 50%;
      }
      .right-side {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
      }

      .delete-btn,
      .dismiss-btn {
        cursor: pointer;
      }
      .delete-btn {
        color: $error500;
      }
    }
  }
</style>
