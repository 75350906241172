<template>
  <div
    v-if="showPositionEdit"
    class="player-position-edit-root"
  >
    <div
      class="player-position-edit-content"
    >
      <div
        v-for="config in positionEditConfig"
        :key="config.label"
        class="player-position-edit-item"
      >
        <Checkbox
          :model-value="config.modelValue"
          @update:modelValue="config.updateFunction"
        />
        <span @click="config.updateFunction">{{ config.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Checkbox from '@/components/common/Checkbox';
import sportIds from '@/services/helpers/sports';

const {
  FOOTBALL_ID,
  BASEBALL_ID,
} = sportIds;

export default {
  emits: ['updatePosition'],
  components: {
    Checkbox,
  },
  props: {
    position: {
      type: String || null,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const selectedSportId = computed(() => store.getters.manualEditSelectedSport);
    const showPositionEdit = computed(() => selectedSportId.value === BASEBALL_ID || selectedSportId.value === FOOTBALL_ID);
    const updatePosition = (position) => {
      emit('updatePosition', position);
    };
    // Baseball position update
    const isPitcher = computed(() => props.position === 'PITCHER' || props.position === 'PITCHER_HITTER');
    const isHitter = computed(() => props.position === 'HITTER' || props.position === 'PITCHER_HITTER');
    const updateBaseballPlayerPosition = (isPitcherUpdatedValue, isHitterUpdatedValue) => {
      if (isPitcherUpdatedValue && isHitterUpdatedValue) {
        updatePosition('PITCHER_HITTER');
      } else if (isPitcherUpdatedValue) {
        updatePosition('PITCHER');
      } else if (isHitterUpdatedValue) {
        updatePosition('HITTER');
      } else {
        updatePosition(null);
      }
    };
    const toggleIsPitcher = () => {
      updateBaseballPlayerPosition(!isPitcher.value, isHitter.value);
    };
    const toggleIsHitter = () => {
      updateBaseballPlayerPosition(isPitcher.value, !isHitter.value);
    };

    // AF position update
    const isQuarterback = computed(() => props.position === 'QUARTERBACK');
    const isOffensiveNonQB = computed(() => props.position === 'OFFENSIVE_NON_QB');
    const isDefensive = computed(() => props.position === 'DEFENSIVE');
    const toggleIsQB = () => {
      updatePosition(!isQuarterback.value ? 'QUARTERBACK' : null);
    };
    const toggleIsOffensiveNonQB = () => {
      updatePosition(!isOffensiveNonQB.value ? 'OFFENSIVE_NON_QB' : null);
    };

    const toggleIsDefensive = () => {
      updatePosition(!isDefensive.value ? 'DEFENSIVE' : null);
    };

    const positionEditConfig = computed(() => {
      if (selectedSportId.value === BASEBALL_ID) {
        return [
          {
            modelValue: isPitcher.value,
            updateFunction: toggleIsPitcher,
            label: 'Is Pitcher',
          },
          {
            modelValue: isHitter.value,
            updateFunction: toggleIsHitter,
            label: 'Is Hitter',
          },
        ];
      }
      if (selectedSportId.value === FOOTBALL_ID) {
        return [
          {
            modelValue: isQuarterback.value,
            updateFunction: toggleIsQB,
            label: 'Is Quarterback',
          },
          {
            modelValue: isOffensiveNonQB.value,
            updateFunction: toggleIsOffensiveNonQB,
            label: 'Is Offensive Non QB',
          },
          {
            modelValue: isDefensive.value,
            updateFunction: toggleIsDefensive,
            label: 'Is Defensive',
          },
        ];
      }
      return [];
    });

    return {
      showPositionEdit,
      positionEditConfig,
    };
  },
};
</script>

<style lang="scss">
.player-position-edit-root {
  .player-position-edit-content {
    padding-top: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    .player-position-edit-item {
      display: flex;
      align-items: center;
      gap: 8px;
      span {
        cursor: pointer;
      }
    }
  }
}
</style>
