<template>
  <Modal
    class="manual-edit-modal"
    :visible="modalVisible"
    :title="modalTitle"
    @close="onEditModalClose"
    no-close-btn
  >
    <EditPlayerModalContent
      :player-data="selectedRow || {}"
      :is-create="isManualEditCreateActive"
      @close-modal="onEditModalClose"
    />
  </Modal>
  <div class="manual-edit-root">
    <Table
      class="manual-edit-table"
      :columns="tableColumns"
      :rows="tableData"
      :loading="isDataLoading"
      :no-data-message="'No available players'"
      @row-clicked="onRowEdit"
    >
      <template #cell="{ column, row }">
        <PlayerTableCell
          :column-key="column.key"
          :copy-available="column.copyAvailable"
          :row="row"
        />
      </template>
    </Table>
    <Footer />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Table from '@/components/common/Table';
import PlayerTableCell from './table-configuration/PlayerTableCell';
import Footer from '@/components/common/Footer';
import Modal from '@/components/common/Modal';
import EditPlayerModalContent from './modals/EditPlayerModalContent';
import { formatPlayerName } from '@/services/helpers/player-setup-mapper';

export default {
  components: {
    Table,
    PlayerTableCell,
    Footer,
    Modal,
    EditPlayerModalContent,
  },
  setup() {
    const store = useStore();
    const tableColumns = computed(() => store.getters.manualEditTableColumns);
    const tableData = computed(() => store.getters.manualEditTableData);
    const isDataLoading = computed(() => store.getters.manualEditDataLoading);
    const selectedRow = computed(() => store.getters.manualEditSelectedRow);
    const isManualEditCreateActive = computed(() => store.getters.manualEditIsCreateNewRecordActive);
    const modalVisible = computed(() => !!selectedRow.value || isManualEditCreateActive.value);
    const modalTitle = computed(() => {
      if (isManualEditCreateActive.value) return 'Create new player';
      return formatPlayerName(selectedRow.value?.name);
    });
    const onRowEdit = (row) => {
      store.dispatch('updateManualEditSelectedRow', row);
    };
    const onEditModalClose = () => store.dispatch('clearManualEditModalData');

    return {
      tableColumns,
      tableData,
      isDataLoading,
      selectedRow,
      modalTitle,
      modalVisible,
      isManualEditCreateActive,
      onRowEdit,
      onEditModalClose,
    };
  },

};
</script>

<style lang="scss">
.manual-edit-modal {
  .modal__dialog {
    min-width: 500px;
    max-width: 550px;
    .modal__header {
      padding: 16px;
      border-bottom: 1px solid $gray400;
      .modal__title {
        padding: 0;
      }
    }
  }
}
.manual-edit-root {
  .manual-edit-table {
    // Navbar height - 69px
    // Header height - 86px
    // Footer height - 57px
    height: calc(100vh - 69px - 86px - 57px);
  }
}
</style>
